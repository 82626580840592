.gmnoprint {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .gm-svpc div {
    margin: 5px 0; 
  }

.vertical-button-container {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 100;
}

.vertical-button-container button {
  background-color: #4CAF50;
  color: white;
  padding: 1px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vertical-button-container button:hover {
  background-color: #45a049;
}


  .current-location-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .current-location-btn:hover {
    background-color: #f1f1f1;
  }
  